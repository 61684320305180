import React from "react"
import { DefaultLayout } from "layouts"
import { CaseStudies } from "containers"
import { Lists } from "components"
import bgHeader from "../../images/bg-header.png"

const Index = (props: any) => {
  const caseStudiesListProps: ICaseStudiesList = {
    headline: "Latest case studies",
    description:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita ",
  }

  const boxWrapperProps = {
    heroProps: {
      background: bgHeader,
      headline: "B&F Case Studies",
    },
    boxContent: <Lists.CaseStudies {...caseStudiesListProps} />,
    breadcrumbProps: {
      paths: [
        {
          path: "/",
          label: "Home",
        },
        {
          path: "/case-studies",
          label: "Case Studies",
        },
      ],
    },
  }

  const caseStudiesProps: ICaseStudiesContainerProps = {
    boxWrapperProps,
  }

  return (
    <DefaultLayout {...props}>
      <CaseStudies {...caseStudiesProps} />
    </DefaultLayout>
  )
}

export default Index
